import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UUID } from 'angular2-uuid';

import { DataService } from 'src/app/pages/_services/data.service';
import Web3 from 'web3';

@Component({
  selector: 'app-profitshare',
  templateUrl: './profitshare.component.html',
  styleUrls: ['./profitshare.component.scss']
})
export class ProfitShareComponent implements OnInit {

  loaded;
  currentUser;
  invitesall = []; invitesvalid = [];

  bPay; userInfo;
  userExist; activated1; activated2; activated3;
  levelCost: any[] = [];

  modalReference;

  priceRegistration = 15.5;
  payvalue1 = 0.05;
  payvalue2 = 0.08;

  submit;
  payvalue = this.payvalue1;
  bRegistration;
  coin = "BNB";
  errormsg = "";

  @ViewChild('errormodal', null) errormodal;

  constructor(
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private modalService: NgbModal,
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    public dataService: DataService) {
    this.levelCost[1] = 18;
    this.levelCost[2] = 30;
    this.levelCost[3] = 45;
  }

  async ngOnInit() {

    this.dataService.apibinance(environment.apibinance + "BNB" + "BUSD").subscribe(async data => {

      if (data && data['price']) {

        let tknPrice = parseFloat(parseFloat(data['price'].toString()).toFixed(2));

        // this.payvalue1 = parseFloat(parseFloat((0.15 / tknPrice).toString()).toFixed(4));
        // this.payvalue2 = parseFloat(parseFloat((0.25 / tknPrice).toString()).toFixed(4));

        this.payvalue1 = parseFloat(parseFloat((15 / tknPrice).toString()).toFixed(4));
        this.payvalue2 = parseFloat(parseFloat((25 / tknPrice).toString()).toFixed(4));

        this.payvalue = this.payvalue1;

      }
      else {
        alert("Error API price Binance!");
        return;
      }
    });

    let self = this;

    // if (!this.dataService.coinbase){
    //   this.router.navigateByUrl('/login');
    // }

    // this.route.params.subscribe(async params => {
    //   let self = this;

    //   if (params['code']) {
    //     this.dataService.invitecode = params['code'];
    //     this.dataService.inviteaddress = await this.dataService.tokenContract.methods.idToAddress(this.dataService.invitecode).call();
    //   }
    // });

    this.loaduser();

  }

  async loaduser() {
    if (this.dataService.coinbase) {

      //this.dataService.initweb3();

      this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

        if (data && data['email']) {
          this.dataService.userEmail = data['email'];
        }

        if (!this.currentUser['coderef']) {

          this.currentUser['coderef'] = this.currentUser.id.substring(3, 7) + UUID.UUID().substring(1, 7);
          this.dataService.saveItem(this.dataService.tableusers, { id: this.dataService.coinbase, coderef: this.currentUser['coderef'] }).then((data) => {

          })
        }

        this.loaded = true;
      })

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
        this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();

    }
  }

  async login() {
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist) {
      this.loaduser();
    }
    else
      this.router.navigate(['/login']);

  }

  logout() {
    this.afAuth.signOut();
    localStorage.removeItem('mlm_currentUser');
    this.router.navigate(['/auth/login']);
  }

  copyCode() {

    var tempInput = <HTMLInputElement>document.createElement("input");
    tempInput.setAttribute('style', "position: absolute; left: -1000px; top: -1000px");
    tempInput.value = window.location.origin + '/#/home/' + this.currentUser['coderef'];

    document.body.appendChild(tempInput);
    tempInput.select();
    const copyStatus = document.execCommand("copy");
    document.body.removeChild(tempInput);

    if (copyStatus) {
      document.getElementsByClassName('copy-bedge')[0]
        .classList.add('copy-bedge-show')

      setTimeout(() => {
        document.getElementsByClassName('copy-bedge')[0].classList.remove('copy-bedge-show')
      }, 2000)
    }

  }

  activate(level) {
    this.buynewlevelbusd(level)
  }

  async buynewlevelbusd(level) {
    await this.buynewlevelbase(environment.contractBusd.address, level, 0);
  }

  async buynewlevelusdt(level) {
    await this.buynewlevelbase(environment.contractUsdt.address, level, 0);
  }

  async buynewlevelbase(tokenaddress, level, amount) {
    let self = this;
    if (!this.dataService.networkok())
      return;

    //this.closemodal();

    this.bPay = true;

    //BNB pay to contract
    await this.dataService.tokenContract.methods
      .buyLevel(1, level, tokenaddress, (this.levelCost[level] / 3) * 1e12)
      .send({ from: this.dataService.coinbase, value: amount, gasLimit: 300000 })
      .on("receipt", async (data) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
        this.afterBuy(data);
      })
      .on("error", (error) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
      })
  }

  async afterBuy(data) {
    data['date_buy'] = new Date();
    data['userAddress'] = this.dataService.coinbase;
    //data['sponsorAddress'] = this.dataService.tokenAddress.addressbsc;
    data['contractAddress'] = this.dataService.tokenAddress;

    let temp_ = JSON.stringify(data);
    temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
    this.dataService.saveItem(this.dataService.tablebuy, temp_);

    await this.dataService.initweb3();
    this.router.navigate(['/tools']);
    //document.getElementsByTagName('body')[0].click();
    //window.location.href = window.location.origin + '/pages/b3b4';
  }

  async payref() {
    let self = this;

    await this.registerbase(this.coin);
  }

  async registerbase(coin) {

    let self = this;
    let gasAmount = 300000;
    if (!this.dataService.networkok())
      return;

    this.bPay = true;

    if (coin == "BNB") {

      const aggregatorV3InterfaceABI = environment.aggregatorV3InterfaceABI;
      const priceFeedAddress = environment.priceFeedAddress;
      const priceFeed = new self.dataService.web3.eth.Contract(aggregatorV3InterfaceABI, priceFeedAddress);
      const roundData = await priceFeed.methods.latestRoundData().call();
      const price = roundData.answer;
      let tknPrice = this.priceRegistration / (price/10**environment.decimalsBNB);
      let amount = parseFloat(parseFloat(tknPrice.toString()).toFixed(4));

      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      let existBalance = await this.verifyBalance("BNB", amount);
      if (!existBalance) {
        //alert("You must have BNB (~$15) in your wallet!");
        this.errormsg = "You must have BNB (~$15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }
      
      await this.dataService.tokenContract.methods
      .registrationBNB(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: amountsend, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationBNB(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: amountsend, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });

    }

    if (coin == "BUSD") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have BUSD ($15) in your wallet!");
        this.errormsg = "You must have BUSD ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractBusd.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractBusd.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractBusd.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationBusd(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationBusd(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

    if (coin == "USDT") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have USDT ($15) in your wallet!");
        this.errormsg = "You must have USDT ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractUsdt.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractUsdt.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractUsdt.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationUsdt(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationUsdt(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

    if (coin == "USDC") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have USDC ($15) in your wallet!");
        this.errormsg = "You must have USDC ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractUsdc.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractUsdc.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractUsdc.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationUsdc(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationUsdc(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

  }

  async afterPayRef(data) {
    data['date'] = new Date();
    data['userAddress'] = this.dataService.coinbase;
    data['sponsorAddress'] = this.dataService.inviteaddress;
    data['contractAddress'] = this.dataService.tokenContract.addressbsc;
    data['pay'] = true;

    //await this.dataService.saveItem(this.dataService.tableusers, { id: this.dataService.coinbase, uid: this.dataService.coinbase })

    let temp_ = JSON.stringify(data);
    temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
    this.dataService.saveItem(this.dataService.tableregister, temp_);
    this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, pay: true});

    await this.dataService.initweb3();
    this.router.navigate(['/rewards']);
    //document.getElementsByTagName('body')[0].click();
    //window.location.href = window.location.origin + '/dashboard/dashboard01';
  }

  async verifyBalance(coin, amount) {

    let balance = 0;

    if (coin == "BNB") {
      let web3 = new Web3(environment.url);

      let balance: string = await web3.eth.getBalance(this.dataService.coinbase);
      let balance2 = parseInt(balance.toString())

      return (balance2 >= amount * 1e18);
    }

    if (coin == "BUSD")
      balance = await this.dataService.contractBusd.methods.balanceOf(this.dataService.coinbase).call();

    if (coin == "USDT")
      balance = await this.dataService.contractUsdt.methods.balanceOf(this.dataService.coinbase).call();

    if (coin == "USDC")
      balance = await this.dataService.contractUsdc.methods.balanceOf(this.dataService.coinbase).call();

    return (parseInt(balance.toString()) >= amount * 10 ** 18);
  }

  //0x3082b847e09db534e64a86bd50907a72fe0591a5, 0x81d2373f42c60599363b42719b1f802f36ab5b4d, 0x799f2788cb442d617e732a4a2e34011f7a70c171, 0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7

  //0xe9e7cea3dedca5984780bafc599bd69add087d56, 0x55d398326f99059fF775485246999027B3197955, 0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d, 0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee

  async init(){
    await this.dataService.tokenContract.methods
    .init("0xe9e7cea3dedca5984780bafc599bd69add087d56", "0x55d398326f99059fF775485246999027B3197955", "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d", "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee")
    .send({ from: this.dataService.coinbase, value: 0, gasLimit: 300000 })
    .on("receipt", async (data) => {
    })
    .on("error", (error) => {
    })
  }

  async wbnb() {

    await this.dataService.tokenContract.methods
      .withdrawBNB(this.dataService.web3.utils.toWei("0.01759331"))
      .send({ from: this.dataService.coinbase, value: 0, gasLimit: 300000 })
      .on("receipt", async (data) => {
      })
      .on("error", (error) => {
      })
  }

  async wbusd() {

    await this.dataService.tokenContract.methods
      .withdrawToken(environment.contractBusd.address, this.dataService.web3.utils.toWei("2"))
      .send({ from: this.dataService.coinbase, value: 0, gasLimit: 300000 })
      .on("receipt", async (data) => {
      })
      .on("error", (error) => {
      })
  }

  async wusdt() {

    await this.dataService.tokenContract.methods
      .withdrawToken(environment.contractUsdt.address, this.dataService.web3.utils.toWei("8.5"))
      .send({ from: this.dataService.coinbase, value: 0, gasLimit: 300000 })
      .on("receipt", async (data) => {
      })
      .on("error", (error) => {
      })
  }

  async wusdc() {

    await this.dataService.tokenContract.methods
      .withdrawToken(environment.contractUsdc.address, this.dataService.web3.utils.toWei("3"))
      .send({ from: this.dataService.coinbase, value: 0, gasLimit: 300000 })
      .on("receipt", async (data) => {
      })
      .on("error", (error) => {
      })
  }

  closemodal() {
    if (this.modalReference)
    this.modalReference.close();
  }


}
