import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { TokenizationComponent } from './pages/tokenization/tokenization.component';
import { BuilderComponent } from './pages/builder/builder.component';
import { VenturesComponent } from './pages/ventures/ventures.component';
import { Home2Component } from './pages/home2/home2.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { BlogComponent } from './pages/blog/blog.component';
import { DetailsComponent } from './pages/details/details.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { VCComponent } from './pages/vc/vc.component';
import { ProfitShareComponent } from './pages/profile/profitshare.component';
import { ChatBotComponent } from './pages/chatbot/chatbot.component';
import { LegalComponent } from './pages/legal/legal.component';
import { LoginComponent } from './pages/login2/login.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';

const routes: Routes = [
  { path: 'account',  loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'pages',  component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'home', component: HomeComponent},
  { path: 'home/:code', component: HomeComponent},
  { path: 'home2', component: Home2Component},
  { path: 'tokenization', component: TokenizationComponent},
  { path: 'builder', component: BuilderComponent},
  { path: 'ventures', component: VenturesComponent},
  // { path: 'blog', component: BlogComponent},
  { path: 'details/:id', component: DetailsComponent},
  { path: 'projects', component: ProjectsComponent}, 
  { path: 'investment', component: Home2Component}, 
  { path: 'vc', component: VCComponent},
  { path: 'profitshare', component: ProfitShareComponent},
  { path: 'chatbot', component: ChatBotComponent},
  { path: 'legal', component: LegalComponent},
  { path: 'login', component: LoginComponent}
];

@NgModule({
  // useHash: true
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
