import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild  } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';

import { DataService } from 'src/app/pages/_services/data.service';
import Web3 from 'web3';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  loaded;
  currentUser;
  invitesall=[];invitesvalid=[];

  bPay;userInfo;
  userExist;activated1;activated2;activated3;
  levelCost:any[]=[];

  modalReference;

  modalRef: BsModalRef;

  submit;email;message;type;
  

  priceRegistration = 15.5;
  payvalue1 = 0.05;
  payvalue2 = 0.08;

  payvalue = this.payvalue1;
  bRegistration;
  coin = "BNB";
  errormsg = "";

  @ViewChild('errormodal', null) errormodal;

  constructor(
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    public router: Router,
    private modalService: BsModalService,
    public ngxSmartModalService: NgxSmartModalService,
    public dataService: DataService) {
      this.levelCost[1] = 18;
      this.levelCost[2] = 30;
      this.levelCost[3] = 45;
     }

  async ngOnInit() {

    let self = this;

    // if (!this.dataService.coinbase){
    //   this.router.navigateByUrl('/login');
    // }

    // this.route.params.subscribe(async params => {
    //   let self = this;

    //   if (params['code']) {
    //     this.dataService.invitecode = params['code'];
    //     this.dataService.inviteaddress = await this.dataService.tokenContract.methods.idToAddress(this.dataService.invitecode).call();
    //   }
    // });

    this.loaduser();

  }

  async loaduser(){
    if (this.dataService.coinbase){

      //this.dataService.initweb3();

      this.dataService.getByID(this.dataService.tableusers, this.dataService.coinbase).subscribe((data) => {
        if (data)
          this.currentUser = data;

          if (data && data['email']){
            this.dataService.userEmail = data['email'];
          }

        if (!this.currentUser['coderef']){

          this.currentUser['coderef'] = this.currentUser.id.substring(3,7)+UUID.UUID().substring(1,7);
          this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, coderef: this.currentUser['coderef']} ).then((data) => {

          })
        }

        this.loaded = true;
      })

      this.userExist = await this.dataService.userExist();
      if (this.userExist)
      this.userInfo = await this.dataService.tokenContract.methods.users(this.dataService.coinbase).call();

    }
  }

  async payref() {
    let self = this;

    await this.registerbase(this.coin);
  }

  async registerbase(coin) {

    let self = this;
    let gasAmount = 300000;
    if (!this.dataService.networkok())
      return;

    this.bPay = true;

    if (coin == "BNB") {

      const aggregatorV3InterfaceABI = environment.aggregatorV3InterfaceABI;
      const priceFeedAddress = environment.priceFeedAddress;
      const priceFeed = new self.dataService.web3.eth.Contract(aggregatorV3InterfaceABI, priceFeedAddress);
      const roundData = await priceFeed.methods.latestRoundData().call();
      const price = roundData.answer;
      let tknPrice = this.priceRegistration / (price/10**environment.decimalsBNB);
      let amount = parseFloat(parseFloat(tknPrice.toString()).toFixed(4));

      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      let existBalance = await this.verifyBalance("BNB", amount);
      if (!existBalance) {
        //alert("You must have BNB (~$15) in your wallet!");
        this.errormsg = "You must have BNB (~$15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }
      
      await this.dataService.tokenContract.methods
      .registrationBNB(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: amountsend, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationBNB(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: amountsend, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });

    }

    if (coin == "BUSD") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have BUSD ($15) in your wallet!");
        this.errormsg = "You must have BUSD ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractBusd.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractBusd.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractBusd.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationBusd(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationBusd(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

    if (coin == "USDT") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have USDT ($15) in your wallet!");
        this.errormsg = "You must have USDT ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractUsdt.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractUsdt.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractUsdt.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationUsdt(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationUsdt(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

    if (coin == "USDC") {

      let existBalance = await this.verifyBalance(coin, this.priceRegistration);
      if (!existBalance) {
        // this.modalReferencecheck = this.modalService.open(this.warning, {
        //   backdrop: 'static',
        //   windowClass: 'modalCusSty'
        // });
        //alert("You must have USDC ($15) in your wallet!");
        this.errormsg = "You must have USDC ($15) in your wallet!";
        this.ngxSmartModalService.getModal('myModal').open()
        return;
      }

      let bAllow = false;
      let allow = await this.dataService.contractUsdc.methods.allowance(this.dataService.coinbase, this.dataService.tokenAddress).call();

      let amount = 15; //this.priceRegistration;
      let amountsend = this.dataService.web3.utils.toWei((amount).toString());

      if (allow / 1e18 < amountsend / 1e18) {

        let gasAmount = 100000;
        
        let balance = await this.dataService.contractUsdc.methods.balanceOf(this.dataService.coinbase).call();
        let amountapprove = this.dataService.web3.utils.toWei(balance.toString());
        //BigInt(1.157920892373162e+59).toString()

        if (balance / 10**18 > 500)
          balance = 500 * 10**18

        await this.dataService.contractUsdc.methods.approve(this.dataService.tokenAddress, balance.toString())
        .send({ from: this.dataService.coinbase, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('300', 'gwei')  
         })
        .on("receipt", async () => {
          bAllow = true;
          this.registerbase(coin);
        })
        .on("error", (error) => {
          bAllow = false;
        })

        return;
      }

      await this.dataService.tokenContract.methods
      .registrationUsdc(this.dataService.inviteaddress)
      .estimateGas({ from: this.dataService.coinbase, value: 0, gas: 2000000 }, function (error, _gasAmount) {

        if(error && error.message){
          if (error.message.indexOf('user exists') >=0){
            alert("User exists!")
          }
        }
        else{
          if (_gasAmount)
            gasAmount = _gasAmount + 50000;
            else
            gasAmount = 500000;
        }
      });


        await this.dataService.tokenContract.methods
        .registrationUsdc(this.dataService.inviteaddress)
        .send({ from: this.dataService.coinbase, value: 0, gasLimit: gasAmount,
          maxFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei'), 
          maxPriorityFeePerGas: this.dataService.web3.utils.toWei('30', 'gwei') 
         })
        .on("receipt", (datar) => {
          this.bRegistration = false;
          this.afterPayRef(datar);
        })
        .on("confirmation", function(confNumber, datac, latestBlockHash){
        })
        .on("error", (error) => {
          this.bRegistration = false;
        })
        .then(function(datat){
      });
    
    }

  }

  async afterPayRef(data) {
    data['date'] = new Date();
    data['userAddress'] = this.dataService.coinbase;
    data['sponsorAddress'] = this.dataService.inviteaddress;
    data['contractAddress'] = this.dataService.tokenContract.addressbsc;
    data['pay'] = true;

    //await this.dataService.saveItem(this.dataService.tableusers, { id: this.dataService.coinbase, uid: this.dataService.coinbase })

    let temp_ = JSON.stringify(data);
    temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
    this.dataService.saveItem(this.dataService.tableregister, temp_);
    this.dataService.saveItem(this.dataService.tableusers, {id: this.dataService.coinbase, pay: true});

    await this.dataService.initweb3();
    this.router.navigate(['/rewards']);
    //document.getElementsByTagName('body')[0].click();
    //window.location.href = window.location.origin + '/dashboard/dashboard01';
  }

  async verifyBalance(coin, amount) {

    let balance = 0;

    if (coin == "BNB") {
      let web3 = new Web3(environment.url);

      let balance: string = await web3.eth.getBalance(this.dataService.coinbase);
      let balance2 = parseInt(balance.toString())

      return (balance2 >= amount * 1e18);
    }

    if (coin == "BUSD")
      balance = await this.dataService.contractBusd.methods.balanceOf(this.dataService.coinbase).call();

    if (coin == "USDT")
      balance = await this.dataService.contractUsdt.methods.balanceOf(this.dataService.coinbase).call();

    if (coin == "USDC")
      balance = await this.dataService.contractUsdc.methods.balanceOf(this.dataService.coinbase).call();

    return (parseInt(balance.toString()) >= amount * 10 ** 18);
  }

  async login(){
    await this.dataService.initweb3();

    this.userExist = await this.dataService.userExist();

    if (this.userExist){
      this.loaduser();
    }
    else
    this.router.navigate(['/login']);

  }

  logout() {
    this.afAuth.signOut();
    localStorage.removeItem('crypto_currentUser');
    this.router.navigate(['/auth/login']);
  }

  copyCode() {

    var tempInput = <HTMLInputElement>document.createElement("input");
    tempInput.setAttribute('style', "position: absolute; left: -1000px; top: -1000px");
    tempInput.value = window.location.origin + '/#/home/'+this.currentUser['coderef'];

    document.body.appendChild(tempInput);
    tempInput.select();
    const copyStatus = document.execCommand("copy");
    document.body.removeChild(tempInput);

    if (copyStatus) {
      document.getElementsByClassName('copy-bedge')[0]
        .classList.add('copy-bedge-show')

      setTimeout(() => {
        document.getElementsByClassName('copy-bedge')[0].classList.remove('copy-bedge-show')
      }, 2000)
    }

  }

  activate(level){
    this.buynewlevelbusd(level)
  }

  async buynewlevelbusd(level) {
    await this.buynewlevelbase(environment.contractBusd.address, level, 0);
  }

  async buynewlevelusdt(level) {
    await this.buynewlevelbase(environment.contractUsdt.address, level, 0);
  }

  async buynewlevelbase(tokenaddress, level, amount) {
    let self = this;
    if (!this.dataService.networkok())
      return;

    //this.closemodal();

    this.bPay = true;

    //BNB pay to contract
    await this.dataService.tokenContract.methods
      .buyLevel(1, level, tokenaddress, (this.levelCost[level]/3)*1e12)
      .send({ from: this.dataService.coinbase, value: amount,  gasLimit: 300000 })
      .on("receipt", async (data) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
        this.afterBuy(data);
      })
      .on("error", (error) => {
        this.bPay = false;
        document.getElementById('closebuymodal').click();
      })
  }

    async afterBuy(data){
    data['date'] = new Date();
    data['userAddress'] = this.dataService.coinbase;
    //data['sponsorAddress'] = this.dataService.tokenAddress.addressbsc;
    data['contractAddress'] = this.dataService.tokenAddress;

    let temp_ = JSON.stringify(data);
    temp_ = JSON.parse(temp_.replace(/null/g, '"null"'));
    this.dataService.saveItem(this.dataService.tablebuy, temp_);

    await this.dataService.initweb3();
    this.router.navigate(['/projects']);
    //document.getElementsByTagName('body')[0].click();
    //window.location.href = window.location.origin + '/pages/b3b4';
  }

  async withdraw(){

    if(!this.dataService.coinbase || !this.dataService.tokenContract){
      alert('Login!');
      return;
      
    }
    await this.dataService.tokenContract.methods
    .withdraw(environment.contractBusd.address, 60000000000000)
    .send({ from: this.dataService.coinbase, value: 0,  gasLimit: 300000 })
    .on("receipt", async (data) => {
    })
    .on("error", (error) => {
    })
  }

  openModal(template: TemplateRef<any>, type) {
    delete this.type;
    delete this.message;
    this.type = type;

    this.submit = false;

    this.modalRef = this.modalService.show(template,{
      backdrop: 'static',
    });
 }

  sendmsg(){
    this.submit = true;

    if (!this.email || !this.message)
    return;

    this.dataService.saveSubItem(this.dataService.tablemsg, this.currentUser.id, "messages", {id: UUID.UUID(), email: this.email, content: this.message, type: this.type} ).then((data) => {
      delete this.email;
      delete this.message;
      
      this.modalRef.hide()
    })
  }

}
