import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/pages/_services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit{

  list;
  term;
  
  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private elementRef: ElementRef) {
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
			let self = this;

			if (params['code']){
				this.dataService.invitecode = params['code'];
        localStorage.setItem(this.dataService.strCookie, params['code']);
			}
    });

    let tempSubscription = this.dataService.getList("projects").subscribe((data) => {
      tempSubscription.unsubscribe();

      this.list = this.dataService.sortField(data, 'winx', 'desc');
      this.list = this.list.slice(0, 5);
    })
  }

  ngAfterViewInit() {
     this.addscript(this.elementRef.nativeElement, "assets/site/js/owl.carousel.min.js", "owlcarouselminjs");
    //  this.addscript(this.elementRef.nativeElement, "assets/site/js/snap.svg-min.js", "snapsvgminjs");
    // this.addscript(this.elementRef.nativeElement, "assets/site/js/jquery.listtopie.min.js", "jquerylisttopieminjs");
    //  this.addscript(this.elementRef.nativeElement, "assets/site/js/animation.js", "jsanimationjs");
    //  this.addscript(this.elementRef.nativeElement, "assets/site/js/custom.js", "jscustomjs");
  }

  addscript(_elem, _file, _class, _class_general = ''){

    let content = document.getElementsByTagName('body')[0];
    let arr = document.getElementsByClassName(_class);
    for (let i = 0; i < arr.length; i++) {
    arr[i].remove();
    i = i-1;
    }

    var s = document.createElement("script");
    s.type = "text/javascript"; //"text/javascript";

    s.classList.add(_class);
    if (_class_general != '')
    s.classList.add(_class_general);

    s.src = _file;
    _elem.appendChild(s);

    return s;
}

getstarted(){
  let element = <HTMLElement>document.getElementById('about');
  this.scrollToElement(element);
}

scrollToElement(element: HTMLElement) {
  window.scroll({ 
    top: element.offsetTop, 
    left: 0, 
    behavior: 'smooth' 
  });
}

}
